import React from "react"
import { Link } from "gatsby"
import techImg from "../../assets/images/new-imgs/technical-assistance.png"

const CustomerSupportWordpress = () => {
  return (
    <section id="customer-support">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 position-relative">
            <div className="svg-img">
              <img src={techImg} alt="Web Hosting" loading="lazy"/>
            </div>
          </div>
          <div className="col-md-6 info-text">
            <h2 className="mb-5 h3-title">Fast Ticketing Customer Support</h2>
            <p>We are ready to assist you in any technical situation.</p>
            <p className="mb-5 "> You can get in touch with our customer support team easily, in just a few clicks. Go
              to the support section within your account and contact us no matter the technical challenge you face for
              your WordPress hosting.</p>
            <Link to="/contact/" className="view-more-button">TEST OUR SUPPORT</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CustomerSupportWordpress;
