import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import InfoBlocks from "../components/Index/InfoBlocks"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import BannerArea from "../components/Common/BannerArea"
import SEO from "../components/App/SEO"
import IntroHostingPlansWordpress from "../components/HostingPlans/IntroHostingPlansWordpress"
import PromoInfoRowWordpress from "../components/PromoInfoRow/PromoInfoRowWordpress"
import CustomerSupportWordpress from "../components/Index/CustomerSupportWordpress"
import SiteBenefitsWp from "../components/SiteBenefits/SiteBenefitsWp"
import FreeHosting from "../components/Common/FreeHosting";
import IntroHostingBgWhite from "../components/HostingPlans/IntroHostingBgWhite";
import freeHostingImg from "../assets/images/wordpress-ssd-hosting.png";


const WordpressSsdHosting = () => {
    return (
        <Layout>
            <SEO title="Efficient Wordpress hosting for your website  - Sitebunker.net"
                 description="Are you looking for WordPress hosting for your site? Discover Sitebunker.net web hosting services, designed for any budget. Free SSL certificate and Anti DDoS protection included"
            />
            <Navbar/>
            <IntroHostingBgWhite
                title={'Efficient WordPress Hosting, adapted to your needs'}
                subtitle={'See which hosting package is right for you and get specialized WordPress support and a premium theme of your choice.'}
            />

            <HostingPlanBlock category="WordPress EN"
                              showPromos={true}
                              discount='35'
                              gift='Premium Wordpress Theme'
            />
            <BannerArea
                pageTitle="Advanced Wordpress Hosting - LiteSpeed WP Cache guaranteed"
                pageSubtitl=""
                pageDescription="Recommended for presentation, news, blog websites and WooCommerce online shops, the WordPress hosting subscriptions available at SiteBunker include multiple benefits such as premium resources for processing, storage, RAM and unlimited I/O"
                svgIllustration="wordpress"
            />
            <FreeHosting image={freeHostingImg}
                         title='Choose your hosting package with and get a premium wordpress theme'
                         text1='Enjoy premium services for your WordPress site. For any chosen hosting package with annual payment you have a premium Wordpress Theme. The offer applies to all of the above packages, except the Start package.'
                         text2='With SiteBunker.net, you have complete qualitative services and packages adapted to any website’s needs.'/>
            <PromoInfoRowWordpress/>
            <InfoBlocks/>
            <ClientFeedback
                title="Find out more about why our customers recommend "
                bold="SiteBunker WordPress hosting services!"
            />
            <SiteBenefitsWp/>
            <CustomerSupportWordpress/>
            <Footer/>
        </Layout>
    )
}

export default WordpressSsdHosting
