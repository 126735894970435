import React from "react"
import antivirusIcon from "../../assets/images/sistem-antivirus.png"
import notifIcon from "../../assets/images/sistem-notificari.png"
import servIcon from "../../assets/images/servicii-web.png"
import { Link } from "gatsby"

const SiteBenefitsWp = () => {
  return (
    <section id="site-benefits">
      <div className="container">
        <div className="title-row">
          <h2 className="h3-title text-center mb-4">EFFICIENT WORDPRESS HOSTING</h2>
        </div>
        <div className="row">
          <div className="col-md-4 benefit-col">
            <img src={servIcon} alt="" loading="lazy" />
            <h3 className="benefits-title">AFFORDABLE SUBSCRIPTIONS</h3>
            <p>Do you want your WordPress hosting resources to be in accordance with the needs of your already launched
              website site or your new online store? Find out more details about the WordPress hosting packages designed
              for any budget.</p>
          </div>
          <div className="col-md-4 benefit-col">
            <img src={notifIcon} alt="" loading="lazy" />
            <h3 className="benefits-title">DAILY BACKUP ASSURED</h3>
            <p>Data backup is assured daily, by using an automatic process which includes an external location. Focus on
              what matters to your online success and leave the hosting on SiteBunker. Contact your support team <Link
                to={"/contact/"}>here.</Link>
            </p>
          </div>
          <div className="col-md-4 benefit-col">
            <img src={antivirusIcon} alt="" loading="lazy" />
            <h3 className="benefits-title">ALWAYS BY YOUR SIDE</h3>
            <p>You will be the first to know about anything that can influence your website. Our team monitors the
              status of your site 24/7 and provides you real-time notifications. Always check the status of your
              WordPress hosting services <Link to={"/status/"}> here.</Link></p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SiteBenefitsWp
