import React, { Component } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ReactTooltip from "react-tooltip"

class IntroHostingPlansWordpress extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isClicked: false,
      promoCode: "JV89GSYI7K"
    }

  }

  showPromoCode() {
    this.setState({ isClicked: true })
  }

  render() {
    const { isClicked, promoCode } = this.state
    return (
      <section id="packages-intro">
        <div className="packages-area hosting-plans-intro container-fluid pt-100">
          <div className="container">
            <div className="section-title">
              <h2 className="text-white h3-title">Premium WordPress Hosting </h2>
              <p className="text-white">Pick the suitable WordPress hosting package for your website and test the
                SiteBunker performance. FREE migration guaranteed if you choose to replace your current hosting
                provider.</p>
            </div>
            <div className="buttons-row row mb-5">
              <div className="col-md-12 col-lg-6 text-right mb-3 mx-auto">
                <a className="accent-btn-standard d-inline-flex align-items-center w-100 justify-content-center">Save 6
                  Months / Annual Payment
                  <i className="bx bx-money ml-1" />
                </a>
              </div>
              {/*<div className="col-md-12 col-lg-6 text-left mb-3">*/}
              {/*  <a className="sm-white-btn-outline d-inline-flex align-items-center w-100 justify-content-center"*/}
              {/*     onClick={() => this.showPromoCode()}>TRY 1 MONTH FREE*/}
              {/*    <i className="bx bx-right-arrow-alt ml-1" />*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
            {/*<div className={`row ${isClicked ? "d-block" : "d-none"}`}>*/}
            {/*  <div className="col-12">*/}
            {/*    <div className="promo text-white text-center mb-5">*/}
            {/*      Choose your preferred WordPress hosting subscription, add it to cart, select the monthly payment and*/}
            {/*      don't forget to use the promo code*/}
            {/*      <span className="promo-code mx-2">*/}
            {/*          <CopyToClipboard text={promoCode}>*/}
            {/*            <span data-tip="Copied!">{promoCode} <i className="bx bx-copy-alt" /> </span>*/}
            {/*          </CopyToClipboard>*/}
            {/*          <ReactTooltip place="top" type="success" effect="solid" textColor="white"*/}
            {/*                        backgroundColor="#17992C"*/}
            {/*                        event="click" isCapture={true} />*/}
            {/*        </span>before finishing the order. Enjoy your 1 month for FREE!*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
    )
  }
}

export default IntroHostingPlansWordpress
